import React from 'react'

import { useAppSelector } from '$store/hooks'

import S1 from './_image/s1.png'
import S2 from './_image/s2.png'
import S3 from './_image/s3.png'
import S4 from './_image/s4.png'

import classes from './WelcomePromoBlock.module.scss'

const list = [
  {
    title: 'Зарегистрируйтесь в программе',
    img: S1.src,
  },
  {
    title: 'Выполняйте бизнес\u00A0-\u00A0задачи',
    img: S2.src,
  },
  {
    title: 'Получайте баллы',
    img: S3.src,
  },
  {
    title: 'Обменивайте баллы на\u00A0поощрения',
    img: S4.src,
  },
]

const WelcomePromoBlock: React.FC = () => {
  const isShowBlock = useAppSelector(
    (state) => state.config.site?.show_welcome_block,
  )

  if (!isShowBlock) {
    return null
  }

  return (
    <div className={classes['block']}>
      <ul className={classes['todo-list']}>
        {list.map(({ title, img }) => (
          <li key={title}>
            <div
              className={classes['img']}
              style={{ backgroundImage: `url(${img})` }}
            />
            <div className={classes['item-text']}>{title}</div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default WelcomePromoBlock
